@import "../../../scss/variables";

.profile-addresses {
    .add {
        width: 100%;
        height: 145px;
        border-radius: 15px;
        background-color: #f4f4f4;
        border: dashed 2px #9b9b9b;
        text-transform: uppercase;
        .text {
            font-family: FuturaStd-Bold;
            padding-left: 14px;
            font-size: 16px;
            color: #8b8b8b;
        }
    }

    hr {
        width: 100%;
        height: 1.2px;
        background-color: #d8d8d8;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    &-title {
        font-family: FuturaStd-Bold;
        font-size: 18px;
        color: #212528;
        text-transform: uppercase;
        padding-bottom: 43px;
    }

    &-card {
        height: 215px;
        @media screen and (max-width: "576px") {
            height: unset;
        }
        border-radius: 15px;
        box-shadow: 0 0 20px 0 $card-hover;
        background-color: #ffffff;
        margin-bottom: 25px;
        .address-row {
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 20px;
        }
        .address-header {
            display: flex;
            justify-content: space-between;
            padding-bottom: 29px;
            .address-title {
                padding-top: 20px;
                padding-left: 20px;
                font-family: FuturaStd-Bold;
                font-size: 16px;
                color: #212528;
            }
            .update-button {
                width: 160px;
                height: 45px;
                border-radius: 0px 15px;
                border-width: 0em;
                background-color: $address-button;
                svg {
                    path {
                        fill: $primary-color;
                    }
                }
                span {
                    padding-left: 5px;
                    font-family: Futura-Medium;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0.08px;
                    color: $primary-color;
                    text-transform: uppercase;
                }
            }
        }
    }

    &-billing {
        border-radius: 15px;
        box-shadow: 0 0 20px 0 $card-hover;
        background-color: #ffffff;
        margin-bottom: 25px;
        .address-row {
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 20px;
        }
        .address-header {
            display: flex;
            justify-content: space-between;
            padding-bottom: 29px;
            .address-title {
                padding-top: 20px;
                padding-left: 20px;
                font-family: FuturaStd-Bold;
                font-size: 16px;
                color: #212528;
            }
            .update-button {
                width: 160px;
                height: 45px;
                border-radius: 0px 15px;
                border-width: 0em;
                background-color: $address-button;
                svg {
                    path {
                        fill: #ff0000;
                    }
                }
                span {
                    padding-left: 5px;
                    font-family: Futura-Medium;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0.08px;
                    color: #ff0000;
                    text-transform: uppercase;
                }
            }
        }
    }
}