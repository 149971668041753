@import "../../../scss/_variables";

.category-tree {
    padding: 18px;
    // padding-left: 50px;
    border-radius: 2px;
    // border: solid 1px #cccccc;
    background-color: #FEFEFE;
    height: 100vh;
    width: 271px;
   // width: 320px !important;
    overflow-y: scroll;
    margin-bottom: 20px;
    .custom-control-input {
        position: unset;
    }
}

.view_all {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
        cursor: pointer;
    }
}

.category-sidebar {
    list-style-type: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &-option {
        font-size: 16px;
        line-height: 24px;
        color: #333333;
        font-family: FuturaStd-Medium;
        text-transform: uppercase;
        cursor: pointer;
        @media screen and (min-width: 1279px) {
            &:hover {
                color: $secondary-color
            }
        }
        &-selected {
            color: $secondary-color;
            font-family: FuturaStd-Heavy;
        }
    }
}

.category-sidebar-sub {
    list-style-type: none;
    li {
        margin-bottom: 10px;
    }
    &-option {
        font-size: 12px;
        line-height: 16px;
       // color:$primary-color;
        color: #333333;
        font-family: FuturaStd-Medium;
        cursor: pointer;
        @media screen and (min-width: 1279px) {
            &:hover {
                // color: $secondary-color
                color:red
            }
        }
        &-selected {
            // color: $primary-color;
            color:red;
        }
    }
    &-sub-option {
        font-size: 12px;
        letter-spacing: 0.11px;
       // color: red;
        color: #333333;
        font-family: FuturaStd-Book;
        cursor: pointer;
        @media screen and (min-width: 1279px) {
            &:hover {
                color: $secondary-color
            }
        }
        &-selected {
            color: $secondary-color;
            font-family: FuturaStd-Bold;
            // font-family: bold;
        }
    }
}
.third-level {
    padding-left: 20px;
}