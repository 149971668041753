.tags {
  display: flex;
  width: 110px;
  height: 110px;
  min-width: 80px;
  min-height: 80px;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #f5f5f5;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  &-image {
    width: 44px;
    height: 44px;
    img{
      width: 100%;
    }
  }
  &-text {
    color: #333;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    font-family: FuturaStd-Heavy;
  }
}
.tags:hover{
  scale: 1.02;
}

.tags-slider{
  padding: 40px;
  .slick-next, .slick-prev{
    background-color: #E2001A !important;
    border-radius: 50% !important;
    width: 30px;
    height: 30px;
    z-index: 1;
    display: flex;
    justify-content: center;
    padding: 5px;
  }
}
