@import "../../../scss/variables";

.checkout-confirm {
    &-title {
        font-family: FuturaStd-Bold;
        font-size: 22px;
        letter-spacing: 1.24px;
        text-align: center;
        color: $primary-color;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    &-subtitle {
        text-align: center;
        max-width: 740px;
        width: 100%;
        margin: 0 auto;
        font-size: 14px;
        font-family: FuturaStd-Book;
        margin-bottom: 10px;
        color: #000;
        b {
            font-family: FuturaStd-Bold;
        }
    }

    &-resume {
        border-radius: 5px;
        border: solid 1px #d8d8d8;
        background-color: #fbfafa;
        padding: 31px 52px;
        max-width: 740px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 40px;
        &-title {
            font-family: FuturaStd-Bold;
            font-size: 16px;
            letter-spacing: 1.01px;
            text-align: center;
            color: #000000;
            text-transform: uppercase;
            margin-bottom: 25px;
            span {
                color: $primary-color;
            }
        }
        .row {
            margin-bottom: 15px;
        }
        .label {
            font-family: FuturaStd-Book;
            font-size: 14px;
            letter-spacing: 0.9px;
            color: #000000;
        }
        .text {
            font-family: FuturaStd-Bold;
            font-size: 16px;
            line-height: 1.44;
            letter-spacing: 0.9px;
            color: #000000;
        }
    }
}