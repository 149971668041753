.filter{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 32px;
    @media screen and (max-width: "991px") {
        padding: 0 10px;
    }
    .total{
        color: #6D6D6D;
        font-family: FuturaStd-Medium;
        font-size: 14px;
        line-height: 20px; 
    }
    .checkbox{
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        input[type="checkbox"]{
            -webkit-appearance: none;
            width: 16px;
            height: 16px;
            border-radius: 4px;
            border: 1px solid #AAADB1;
        }
        input[type="checkbox"]:checked{
            background-color: #FF263F;
        }
        &-container{
            display: flex;
            align-items: center;
            gap: 5px;
        }
        label{
            color: #333;
            font-family: FuturaStd-Medium;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 0;
        }
        .checkbox-contianer{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
        }
    }
    .select{
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        select{
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 274px;
            height: 60px;
            padding: 16px;
            border-radius: 24px;
            border: 1px solid #7D848C;
            background-image: url('../../../images/down.svg'); /* Standard syntax; must be last */
            background-position: right 13px center;
            background-repeat: no-repeat;
        }
        button{
            border: 0;
            color: #E2001A;
            font-size: 16px;
            line-height: 24px;
            text-transform: capitalize;
            background-color: transparent;
        }
    }
}