.order-large{
    display: block;
    @media screen and (max-width: "1350px") {
        display: none;
    }
}
.order-medium{
    display: none;
    .card-title, title{
        word-break: break-word;
    }
    @media screen and (max-width: "1350px") {
        display: block;
    }
}