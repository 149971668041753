.payments {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  .image-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .buttons-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 200px;
  }
  .title {
    font-size: 36px;
    font-family: FuturaStd-Bold;
    line-height: 40px;
    letter-spacing: -1px;
  }
  .text{
    font-size: 16px;
    font-family: FuturaStd-Medium;
    line-height: 24px;
  }
  .bold{
    font-family: FuturaStd-Heavy;
  }
}
