@import './scss/_variables.scss';

input:active,
select:active,
textarea:active,
button:active {
    outline: none;
}

.outline,
.outline:hover,
.outline:focus,
.outline:active {
    outline: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

input:hover,
select:hover,
textarea:hover,
button:hover {
    outline: none;
}

.link {
    color: #212529;
    text-decoration: none;
}

.link:hover {
     color:#212529; 
     text-decoration:none; 
     cursor:pointer;  
}

.g-padding {
    padding-left: 20px!important;
    padding-right: 20px!important;
    @media screen and (min-width: 992px) {
        padding-left: 56px!important;
        padding-right: 56px!important;
    }
}

.g-lg-padding {
    @media screen and (min-width: 1500px) {
        padding-left: 56px;
        padding-right: 56px;
    }
}

.button-clean {
    margin: 0;
    padding: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
}

.primary-text {
    color: $primary-color;
    font-family: FuturaStd-Bold;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
}

.primary-color {
    color: $primary-color;
}

.truncate {
    --max-lines: 2;
    position: relative;
    max-height: calc(var(--lh) * var(--max-lines));
    padding-right: 1rem; /* space for ellipsis */
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    overflow: hidden;
  }

// bootstrap ovverides
$input-focus-box-shadow: white;
$input-focus-border-color: #ced4da;
$input-disabled-bg: white;
$primary: $primary-color;

// bootstrap
@import '../node_modules/bootstrap/scss/bootstrap.scss';

// toasted
@import '../node_modules/toasted-notes/src/styles.css';

// react-image-gallery
@import "~react-image-gallery/styles/scss/image-gallery.scss";

// react-toastify
@import '../node_modules/react-toastify/dist/ReactToastify.css';

// react-datepicker
@import "../node_modules/react-datepicker/dist/react-datepicker.css";
@import "../node_modules/react-datepicker/dist/react-datepicker-cssmodules.css";

// react loader spinner
@import "../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// react slick
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// fonts
@font-face {
    font-family: "FuturaStd-Bold"; 
    src: url("../src/fonts/FuturaStd-Bold/FuturaStd-Bold.woff");  
}
@font-face {
    font-family: "FuturaStd-Book"; 
    src: url("../src/fonts/FuturaStd-Book/FuturaStd-Book.woff");  
}
@font-face {
    font-family: "FuturaStd-Heavy"; 
    src: url("../src/fonts/FuturaStd-Heavy/FuturaStd-Heavy.woff");  
}
@font-face {
    font-family: "FuturaStd-Medium"; 
    src: url("../src/fonts/FuturaStd-Medium/FuturaStd-Medium.woff");  
}

body {
    margin: 0;
    padding: 0;
    font-family: FuturaStd-Book;
    max-width: 100%;
}

// .mobile-view-search {
//     // height: stretch;
//     // height: -webkit-fill-available;
//     // height: -moz-fill-available;
//     @media screen and (max-width: "991px") {
//         padding-top: 48px;
//     }
// }

.mobile-checkbox {
    @media screen and (max-width: "576px") {
        margin-left: 20px;
    }
}

ul {
    padding-inline-start: unset
}

.main-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    max-width: 2000px;
    margin: 0 auto;
}

.content {
    flex: 1;
   // padding-bottom: 300px;
}

.navigation {
    height: 85px;
    position: relative;
    z-index: 101;
}

.navigation-wrapper {
    // display: block;
    flex-direction: column;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 102;
}

.navigation-bar {
    background: #fff;
    border-bottom: 2px solid #f7f7f7;
    height: 85px;
    position: relative;
    z-index: 103;
    min-width: 320px;
}

.navigation-column {
    flex-grow: 1;
}

.navigation-logo {
    // height: 85px;
    // width: 192px;
    // Coop-Puglia
    height: 85px;
    width: 120px;
}

.navigation-logo img {
    max-width: 100%;
    width: auto;
}

.logo-parent {
    padding-left: 56px;
}

.navigation-logo-break {
    display: none;
    width: 100%;
}

.navigation-item {
    display: block;
    text-decoration: none;
    cursor: pointer;
}

.navigation-item-anchor {
    cursor: pointer;
    font-family: FuturaStd-Book;
    user-select: none;
}

.navigation-box {
    padding: 2px;
    height: 83px;
    cursor: pointer;
    font-family: FuturaStd-Bold;
    font-size: 10px;
    margin-right: 1px;
    position: relative;
    text-transform: uppercase;
    user-select: none;
    min-width: 50px;
    width: 83px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: "991px") {
        height: 55px;
        width: 25px;
        min-width: unset;
        margin-left: 13px;
        .navigation-box__icon {
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    &:hover {
        color: $primary-color;
        .navigation-box__icon {
            svg {
                path {
                    fill: $primary-color;
                }
            }
        }
    }

    &__active {
        @media screen and (min-width: "992px") {
            background: $primary-color;
            color: #fff;
            &:hover {
                color: #fff;
                .navigation-box__icon {
                    svg {
                        path {
                            fill: white;
                        }
                    }
                }
            }
            .navigation-box__icon {
                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }
    }
}

.navigation-box__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    @media screen and (max-width: "991px") {
        height: unset;
        top: 30%;
    }
}

.navigation-box__spacer {
    height: 16px;
    width: 100%;
    @media screen and (max-width: "991px") {
        height: 35px;
        padding-top: 22px;
    }
}

.navigation-box__icon {
    height: 27px;
    position: relative;
    width: 45%;
    color: #878F96;
}

.navigation-rect {
    background: $primary-color;
    color: #fff;
    cursor: pointer;
    font-family: FuturaStd-Bold;
    font-size: 10px;
    height: 83px;
    position: relative;
    text-transform: uppercase;
    user-select: none;
    width: 170px;
}

.navigation-rect__wrapper {
    height: 30px;
    margin-top: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.navigation-rect__icon {
    display: inline-block;
    height: 100%;
    vertical-align: top;
    width: 22px;
    position: relative;
    margin-right: 27px;
    padding-top: 5px
}

.navigation-rect__label {
    display: inline-block;
}

.navigation-view {
    padding-top: 40px;
    padding-bottom: 48px;
    margin: 0 auto;
    max-width: 990px;
    width: 100%;
}

.category-navigation-view {
    margin: 0 auto;
    width: 100%;
    padding-left: 5%;
    .column {
        padding-bottom: 8px;
    }
    @media screen and (max-width: "576px") {
        padding-left: 30px;
    }
}

.Modal {
    top: 120px; // balancing header height
    @media screen and (max-width: 991px) {
        top: 143px; // balancing header height
    }
    position: fixed;
    z-index: 500;
    background-color: #ffffff;
    color: black;
    width: 100%;
    box-shadow: 0.3px 0.3px 0.3px #f7f7f7;
    box-sizing: border-box;   
    max-height: 100%;
    @media screen and (max-width: "576px") {
        overflow-y: auto;
        padding-top: unset!important;
        height: 600px;
    }
}

.modal-mobile-scrolling {
    @media screen and (max-width: "576px") {
        overflow-y: auto;
        height: 600px;
    }
}

.modal-close {
    color: #333;
    font-size: 35px;
    // float: right;
    background-color: #ffffff;
    border: none;
}

.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    background-color: rgba(0,0,0,0.5);
}

.Carousel div {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    // height: 100%;
    // height: 50vh;
    // width: 100%;

    img {
        width: 100%;
        display: unset;
        pointer-events: unset;
    }
    // @media screen and (max-width: "1199px") {
    //     height: 330px;
    // }
    // @media screen and (max-width: "991px") {
    //     height: 245px;
    // }
    // @media screen and (max-width: "575px") {
    //     height: 200px;
    // }
}

.page-grid {
    display: flex;
    // padding: 25px 40px 40px 40px;
    padding-bottom: 40px;
    align-items: flex-start;

    @media screen and (max-width: "1279px") {
        padding: 25px 0 40px 0;
    }

    @media screen and (max-width: "1023px") {
        padding: 25px 0 40px 0;
    }

    // column-gap: 20px;

    .grid-left {
        //width: 20% ;
        // width: 320px;
        // width: 23%;

        // margin: 20px 0 20px 20px;
        margin: 16px;
        margin-right: 0;
        position: -webkit-sticky;
        position: sticky;
        top: 140px;

        // @media screen and (max-width: "1279px") {
        //     display: none;
        // }

        @media screen and (max-width: "991px") {
            display: none;
        }
    }

    .grid-right {
        // width: 80%;
        width: calc(100vw - 319px);
        // width: 77%;

        // margin: 20px 20px 20px 0;

        //margin: 32px 32px 20px 0;

        margin: 16px;

        @media screen and (max-width: "991px") {
            width: 100%;
            padding-top: unset !important;
            margin: 20px 0;

        }


        /*   @media screen and (max-width: "544px") {
            width: 100%;
            padding-top: unset !important;
            margin: 32px 0 20px 0;
           
        }    */

    }
}

.wrapper {    
    @media screen and (max-width: "576px") {
        overflow-y: auto;
        max-height: 90vh;
    }

    padding-top: 60px;
    &-box {
        background-color: #f2f2f2;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 28px -6px;
    }
}

.signup-wrapper {
    margin-top: 60px;
    margin-bottom: 40px;
}

.signup-form {
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
}

.signup-form-title {
    font-family: FuturaStd-Bold;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 33px;
    display: inline-block;
}

.signup-row {
    margin-bottom: 15px;
}

.custom-input {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    background: transparent;
    font-family: FuturaStd-Bold;
    font-size: 14px;
    line-height: 1.5;
    padding: .375rem .75rem;
    padding-left: 0;
    width: 100%;
}

.custom-input-placeholder {
    color: rgba(51,51,51,.5);
    font-family: FuturaStd-Book;
    font-size: 14.5px;
    left: 100;
    line-height: 1.5;
    max-width: 100%;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: .375rem;
    white-space: nowrap;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}

.custom-input-placeholder-elevated {
    top: -10px;
    font-size: 12px;
}

.custom-control {
    min-height: 4rem;
    @media screen and (max-width: "576px") {
        min-height: 7rem;
    }
}

.custom-control-label {
    font-size: 15px;
    @media screen and (max-width: "370px") {
        font-size: 12px;
    }
    @media screen and (max-width: "290px") {
        font-size: 10px;
    }
}

.form-control-invalid {
    width: 100%;
    color: #dc3545;
    font-size: 80%;
}

.signup-textarea {
    resize: none;
    padding: 15px;
    font-size: 13px;
    font-family: FuturaStd-Bold;
}
.signup-textarea:read-only {
    background-color: #ffffff
}

.signup-radio-label {
    // background-image: url();
    background-repeat: no-repeat;
    font-family: FuturaStd-Book;
    background-size: 15px 15px;
    background-position: 0;
    padding-left: 8px;
    margin: 0;
    margin-right: 15px;
    cursor: pointer;
}

.custom-button {
    background: $primary-color;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: FuturaStd-Bold;
    text-transform: uppercase;
    font-size: 12px;
    height: 50px;
    line-height: 50px;
    width: 100%;
    border-radius: 8px;
}

.custom-slist-button {
    background: #fff;
    border: 0;
    color: #333;
    cursor: pointer;
    font-family: FuturaStd-Book;
    font-size: 13px;
    height: 50px;
    line-height: 50px;
    width: 100%;
}

.custom-slist-button :hover {
    background: #fff;
}

.custom-button-disabled {
    background: #c1c1c1;
    pointer-events: none;
}

.button-clean-disabled {
    color: #c1c1c1;
    pointer-events: none;
}

.custom-button-hide {
    display: none;
}

.title {
    font-family: FuturaStd-Bold;
    font-size: 16px;
    text-transform: uppercase;
    display: inline-block;
}

.subtitle {
    color: #333;
    font-family: FuturaStd-Book;
    font-size: 16px;
    margin-bottom: 15px;
}

.login-padding {
    padding-top: unset;
    @media screen and (max-width: "576px") {
        padding-top: 40px;
    }
}

.login-form-paragraph {
    font-size: 12px;
    margin-bottom: 40px;
}

.login-link {
    color: $primary-color;
    font-family: FuturaStd-Bold;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
}

.login-link:hover {
    color: $primary-color; 
    text-decoration: none; 
    cursor: pointer;  
}

.login-icon {
    padding-top: 7px
}

.modal-profile-name {
    font-size: 32px;
    line-height: 1;
    margin-bottom: 50px;
}

.modal-profile-wrapper {
    max-width: 800px;
    margin: 0 auto;
}

.modal-profile-field {
    text-align: center;
    margin-bottom: 20px;
}

.smartbip-datepicker {
    font-family: FuturaStd-Book;
    font-size: .8rem;
    background-color: #fff;
    position: relative;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, 
.react-datepicker__close-icon::after {
    background-color: $primary-color!important;
}

.featured-section-title {
    font-family: FuturaStd-Heavy;
    text-align: left;
    font-size: 25px;
    padding: 40px 20px;
    color: #333;
    @media screen and (max-width: 575px) {
        font-size: 20px;
    }
}

.search-product-view {
    padding-top: 40px;
    padding-bottom: 48px;
    margin: 0 auto;
    width: 100%;
}

.search-product {
    height: 100px;
    color: #333;
    font-family: FuturaStd-Book;
    text-decoration: none;
    margin-bottom: 10px;
    margin-top: 5px;
}

.search-product-description {
    color: #333;
    font-family: FuturaStd-Book;
    text-decoration: none;
    padding-top: 40px
}

.search-products-image {
    background: #fff;
    position: relative;
    height: 100px;
    width: 100px;
}

.search-products-image img {
    left: 50%;
    max-height: 65%;
    max-width: 65%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.search-row-handling {
    @media screen and (min-width: 0px) and (max-width: 649px) {
        justify-content: space-between;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
        justify-content: space-between;
    }
}

.search-col-handling {
    flex: 0 0 50%;
    max-width: 47%;
    @media screen and (min-width: 650px) {
        flex: 0 0 33%;
        max-width: 33%;
    }
    @media screen and (min-width: 768px) {
        flex: 0 0 50%;
        max-width: 47%;
    }
    @media screen and (min-width: 992px) {
        flex: 0 0 33%;
        max-width: 33%;
    }
    @media screen and (min-width: 1200px) {
        flex: 0 0 25%;
        max-width: 25%;
    }
    @media screen and (min-width: 1500px) {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.view-container {
    padding: 32px;
}

.cart-container {
    padding: 32px;
    @media screen and (max-width: 567px) {
        padding-left: 0px;   
        padding-right: 0px;
        padding-top: 60px;
    }
}

.cart-lists {
    cursor: pointer;
    min-height: 50px;
    line-height: 50px;
    color: #333;
    font-family: FuturaStd-Book;
    text-decoration: none;
    margin-bottom: 20px;
    margin-top: 5px;
}

.cart-menu-title {
    display: block;
    font-family: FuturaStd-Bold;
    font-size: 12px;
    text-transform: uppercase;
    @media screen and (max-width: 567px) {
        padding-left: 10px;
    }
}

.cart-message {
    display: block;
    font-size: 17px;
    font-family: FuturaStd-Book;
}

.cart-menu-message {
    display: block;
    font-family: FuturaStd-Bold;
    font-size: 16px;
    text-transform: uppercase;
}

.cart-actions-spacer {
    padding: 30px 0 45px;
}

.cart-actions-link {
    color: $primary-color;
    font-family: FuturaStd-Bold;
    font-size: 12px;
    text-decoration: none;
    text-transform: uppercase;
}

.cart-actions-link:hover {
    color: $primary-color; 
    text-decoration: none; 
    cursor: pointer;  
}

.cart-heading {
    color: #c1c1c1;
    font-family: FuturaStd-Book;
    font-size: 12px;
    text-transform: uppercase;
    @media screen and (min-width: 1200px) {
        padding-right: 6px; // balancing scrollbar width
    }
}

.cart-product-image {
    height: 65px;
    width: 65px;
    background: #fff;
    position: relative;
}

.cart-product-image img {
    left: 50%;
    max-height: 50px;
    max-width: 50px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.cart-product-details {
    position: relative;
    top: 30%;
    padding : 0px 0px 0px 0px;
    font-size: 14px;
    font-family: FuturaStd-Book;
}

.cart-product-total {
    font-size: 14px;
    font-family: FuturaStd-Bold;
}

.cart-product-description {
    // white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    margin: 0;
}

.cart-product-description__promo {
    font-size: 10px;
    text-transform: uppercase;
    color: red;
}

.cart-item-scrollable {
    @media screen and (min-width: 1200px) {
        overflow-y: auto;
        max-height: calc(75vh - 115px); // max vh - header height
    
        &::-webkit-scrollbar {
            width: 6px;
            height: 126px;
        }
        
        &::-webkit-scrollbar-track {
            border-radius: 5px;
            background-color: #d8d8d8;
        }
        
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: $primary-color;
        }
    }
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    transition: 0.3s;
    border: none;
    cursor: pointer;

    .card-header {
        background-color: unset;
    }
}

.card-subtitle {
    font-size: 14px;
    color: #333;
    font-family: FuturaStd-Book;
    margin-bottom: 15px;
}

.card-text-muted {
    color: #6c757d;
    font-size: 80%;
    font-weight: 400;
}

.card-text {
    font-size: 80%;
    font-weight: 400;
}

.card-selected {
    background-color: $primary-color!important;
    color: white;
}

.card-selected .subtitle  {
    color: white;
}

.card-selected .card-subtitle  {
    color: white;
}

.card-selected .card-text-muted  {
    color: white;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

// .card-image {
    // left: 55%;
    // max-height: 100%;
    // max-width: 100%;
    // position: absolute;
    // top: 50%;
    // transform: translate(-50%,-50%);
    // width: 150px;
    // max-width: 90%;
    // height: auto;
// }

.order-status-COLLECTED,
.order-status-DELIVERED {
    color: green
} 

.order-status-TO_BE_PREPARED, 
.order-status-IN_PREPARATION {
    color: gold;
}

.order-status-ISSUING_RECEIPT,
.order-status-TO_BE_COLLECTED,
.order-status-TO_BE_DELIVERED {
    color: orange
}

.order-status-CANCELLED,
.order-status-ABORTED,
.order-status-REFUSED {
    color: red
} 

.card-selected .order-status-TO_BE_PREPARED,
.card-selected .order-status-IN_PREPARATION,
.card-selected .order-status-ISSUING_RECEIPT,
.card-selected .order-status-TO_BE_COLLECTED,
.card-selected .order-status-TO_BE_DELIVERED,
.card-selected .order-status-COLLECTED,
.card-selected .order-status-CANCELLED,
.card-selected .order-status-ABORTED,
.card-selected .order-status-REFUSED {
    color: white;
    transition: 0.3s;
}

.category-list-item {
    color: #333;
    cursor: pointer; 
    text-transform: uppercase;
    font-family: FuturaStd-Book;
    font-size: 18px;
    padding-bottom: 20px;
    &.favorites {
        color: $primary-color!important;
        font-family: FuturaStd-Bold;
    }
}

.product-detail {
    text-align: center;
}

.product-detail-name {
    font-family: FuturaStd-Heavy;
    font-size: 24px;
    margin-bottom: 2px;
    line-height: 28px;
    letter-spacing: -1px;
}

.product-detail-promotion {
    font-size: 16px;
    font-family: FuturaStd-Bold;
    text-transform: uppercase;
    color: red;
    margin-bottom: 15px;
    letter-spacing: 1.5px;
}

.product-detail-netPrice {
    font-size: 14px;
    margin-bottom: 16px;
    line-height: 20px;
}

.product-detail img {
    max-height: 500px;
    max-width: 550px;
    width: 100%;
    min-width: 300px;
    min-height: 250px;
}

.product-price {
    font-family: FuturaStd-Heavy;
    font-size: 16px;
    line-height: 24px;
}

.product-unavaible {
    background-color: #e2e2e2;
    margin-top: 35px;
    padding: 20px;
    span {
        font-family: FuturaStd-Bold;
        font-size: 14px;
        letter-spacing: 0.79px;
        text-align: center;
        color: #d81818;
        text-transform: uppercase;
    }
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    @media screen and (max-width: 567px) {
        max-height: 30vh;
    }
    max-height: 50vh;
}

.image-gallery-slide-wrapper {
        // max-width: 700px;
        margin: 0 auto;
}

.image-gallery-icon {
    filter: none;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    color: #dfdfdf;
    box-shadow: none;
    &:hover {
        color: $primary-color;
    }
}

.image-gallery-bullets {
    bottom: -30px;

    & .image-gallery-bullet {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: #dfdfdf;
        border-radius: 50%;
        box-shadow: none;
        cursor: pointer;
        display: inline-block;
        margin: 0 5px;
        outline: none;
        padding: 5px;
        transition: background .2s ease-out;
        &:hover {
            background: $primary-color;
        }
    }

    & .image-gallery-bullet.active {
        background: $primary-color;
    }
}

.product-detail-heading {
    font-family: FuturaStd-Bold;
    font-size: 12px;
    margin-bottom: 7px;
    text-transform: uppercase;
    padding-right: 10px;
    svg {
        width: 30px;
    }
}

.hr-items {
    margin: unset;
    &:last-of-type {
        display: none;
    }
}

.check-item {
    &-updated {
        font-family: FuturaStd-Bold;
        font-size: 12px;
        line-height: 1.42;
        letter-spacing: 0.44px;
        text-transform: uppercase;
        color: #ffb100;
    }
    &-removed {
        font-family: FuturaStd-Bold;
        font-size: 12px;
        line-height: 1.42;
        letter-spacing: 0.44px;
        text-transform: uppercase;
        color: #d81818;
    }
} 

.product-detail-ref {
    display: block;
    font-family: FuturaStd-Book;
    font-size: 12px;
    margin-bottom: 15px;
    color: grey;
}

.product-detail-rowNetPrice {
    display: inline-block;
    font-size: 18px;
    margin-bottom: 15px;
}

.product-detail-actions-container {
    // max-width: 290px;
    // margin: 55px 0 63px;
    width: 100%;
}

.product-detail-counter {
    max-width: 151px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-detail-counter button {
    background: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    height: 100%;
    text-align: center;
    .trash{
        fill: none !important;
    }
}

.product-detail-counter input {
    width: 100%;
    width: 172px;
    height: 40px;
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
    font-family: FuturaStd-Heavy;
    &:disabled {
        background-color: white;
    }
}

.product-info-title {
    display: block;
    font-family: FuturaStd-Bold;
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
}

.product-info {
    font-family: FuturaStd-Book;
    margin-top: 22px;
}

.product-info h4 {
    margin: 0;
    padding: 0;
    color: #a8a8a8;
    font-size: 10px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.product-info h4 strong {
    font-family: FuturaStd-Bold;
}

.product-info p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    margin-bottom: 17px;
}

.checkout-container {
    background: #fff;
    font-family: FuturaStd-Book;
    padding-bottom: 55px;
}

.checkout-header {
    min-height: 50px;
    padding-bottom: 7px;
    background: #fff;
    padding-top: 7px;
}

.checkout-button {
    width: 274px;
}

.checkout-controls {
    max-width: 500px;
    width: 100%;
}

.checkout-controls-step {
    color: rgba(0,0,0,.3);
    font-family: FuturaStd-Bold;
    font-size: 12px;
    text-transform: uppercase;
    pointer-events: none;
}

.checkout-controls-step-completed {
    color: #000;
}

.checkout-controls-step-current {
    color: #000;
    border-bottom: 1px solid #000;
}

.go-back-button {
    background: transparent;
    border: 0;
    color: $primary-color;
    cursor: pointer;
    font-size: 18px;
    @media screen and (min-width: 768px) {
        padding-left: 25px;
    }
}

.checkout-view {
    margin-top: 40px;
    max-width: 1170px;
    width: 100%;
}

.disponibilityInfo {
    margin: 0 0 20px;
    font-size: 18px;
    font-weight: 700;
}

.disponibility-button {
    height: 130px!important;
    width: 130px!important;
    background-color: #f7f7f7;
    // background: #fff;
    border: 0;
    color: #000;
    cursor: pointer;
    // margin: 0 10px 20px;
    padding: 0;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.disponibility-button-selected {
    background-color: $primary-color;
    color: #fff;
}

.disponibility-ranges {
    max-width: 680px;
    margin: 0 auto;
}

.disponibility-ranges-item {
    display: inline-block;
    margin: 0 20px 20px;
}

.pill {
    background: transparent;
    border-radius: 50px;
    border: 1px solid #dfdfdf;
    color: #333;
    cursor: pointer;
    font-family: FuturaStd-Book;
    line-height: 25px;
    // font-size: 16px;
    // padding: 10px 25px;
    font-size: .75rem;
    padding: 5px 25px;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.pill-disabled {
    border-color: #f5c6cb;
    background-color: #f8d7da;
    pointer-events: none;
}

.pill-active {
    background: $primary-color!important;
    border-color: $primary-color!important;
    color: #fff;
}

.checkout-data {
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
    hr {
        height: 0.4px;
        background-color: $primary-color;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .checkout-textarea {
        &::placeholder {
            font-family: Futura-Medium;
            font-size: 14px;
            letter-spacing: 0.6px;
            color: #bebebe;
        }   
    }
    .form-group {
        margin-bottom: unset;
    }
    .custom-control {
        min-height: unset;
    }
    .custom-control-label {
        padding-top: 2px;
        padding-left: 15px;
        font-family: FuturaStd-Bold;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.7px;
        color: #000000;
    }
    .address-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .add-link {
            font-family: Futura-Medium;
            font-size: 12px;
            letter-spacing: 0.11px;
            color: $primary-color;
            cursor: pointer;
        }
    }
}

.checkout-billing-address {
    margin-top: 20px;
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    .internal-container {
        padding-left: 84px;
    }
    .radio-button {
        padding-left: 30px;
        padding-top: 20px;
    }
    .label {
        font-family: FuturaStd-Book;
        font-size: 12px;
        letter-spacing: 0.6px;
        color: #7a7a7a;
        padding-bottom: 11px;
    }
    .info {
        font-family: FuturaStd-Bold;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #212528;
        padding-bottom: 6px;
    }
}

.checkout-summary {
    width: 100%;
    font-size: 18px;
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    background-color: #fbfafa;
    padding: 24px 30px;
    font-family: FuturaStd-Book;
    font-size: 14px;
    letter-spacing: 0.7px;
    color: #000000;
    margin-bottom: 20px;
}

.checkout-summary-total {
    border-top: 1px solid #d8d8d8;
}

.checkout-summary-total-text {
    font-family: FuturaStd-Bold;
    text-transform: uppercase;
    &-info {
        font-family: FuturaStd-Bold;
        text-transform: unset;
    }
}

.checkout-subtitle {
    font-family: FuturaStd-Book;
    font-size: 14px; 
    line-height: 1.43;
    letter-spacing: 0.7px;
    color: #000000;
}

.checkout-data button, .addMethod {
    margin: 0px 0px 20px 0;
    @media screen and (max-width: "576px") {
        margin: unset
    }
    cursor: pointer;
    height: 80px;
    border: 1px solid #d2d1d1;
    background: transparent;
    color: #333;
    line-height: 20px!important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: FuturaStd-Book;
    font-size: 12px;
    width: 100%;

    &.mobile {
        display: inline; 
        font-family: FuturaStd-Bold;
        text-transform: uppercase;
        font-size: 14px;
        color: $primary-color;
        background: #fff;
    }
    &.mobile-active {
        display: inline; 
        font-family: FuturaStd-Bold;
        text-transform: uppercase;
        font-size: 14px;
        background: $primary-color;
        color: #fff;
    }
}

.checkout-data button:hover {
    background: $primary-color;
    color: #fff;
}
.checkout-data .button-active {
    background: $primary-color;
    color: #fff;
}

.hover-text {
    display: none
}

.checkout-data .selected-payment-text {
    display: inline; 
    font-family: FuturaStd-Bold;
    text-transform: uppercase;
    font-size: 14px;
}

.checkout-data button:hover .to-hide { display: none; }
.checkout-data button:hover .hover-text {
     display: inline; 
     font-family: FuturaStd-Bold;
     text-transform: uppercase;
     font-size: 14px;
}

.checkout-data .checkout-button-disabled {
    background: #c1c1c1 !important;
    pointer-events: none;
}

.checkout-summary img {
    max-width: 250px;
    height: auto;
}

.text-strike {
    text-decoration: line-through;
    color : #777777;
    font-family: FuturaStd-Book;
}

.map-container {
    display: flex;
    justify-content: center;
}

.leaflet-container {
    z-index:1; 
    width: 85%;
    height: 325px;
}
  
.pagination {
    margin-top: 25px;
    // margin-bottom: 25px;
}

.pagination span {
    cursor: pointer;
    color: black;
    float: left;
    padding: 6px 12px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
}
  
.pagination span.active {
    color: white;
    background-color: $primary-color;
    border: 1px solid $primary-color;
}

.home-item {
    margin: 0 10px 50px;
    width: 100%;
    max-width: 235px;
    @media screen and (max-width: "576px") {
        max-width: 160px;
    }
    color: #333;
    font-family: FuturaStd-Book;
}
 
.home-item-content {
    background: #fff;
    position: relative;
}

.home-item-header {
    height: 245px;
    @media screen and (max-width: "576px") {
        height: 200px;
    }
    position: relative;
}

.home-item-header-promo {
    font-family: FuturaStd-Book;
    padding: 15px;
    position: relative;
    z-index: 1;
}

.home-item-image {
    height: 100%;
    width: 100%;
    left: 50%;
    max-height: 70%;
    max-width: 70%;
    padding: 20px 0 10px;
    position: absolute;
    text-align: center;
    top: 57%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.home-item-image img {
    max-width: 100%;
    max-height: 100%;
}

.home-item-actions {
    border-top: 1px solid #f7f7f7;
    background: #fff;
    position: relative;
}

.home-item-actions .items {
    border-bottom: 1px solid #f7f7f7; 
    height: 50px;
    border-right: 1px solid #f7f7f7;
}

.home-item-actions button {
    background: transparent;
    border: 0;
    cursor: pointer;
}

.home-item-actions span {
    color: #c1c1c1;
}

.home-button-items :hover,
.home-button-items :focus,
.home-button-items :active {
    background-color: $primary-color;
}

.home-button-items :hover span,
.home-button-items :focus span,
.home-button-items :active span {
    color: white;
}

.home-item-footer .info {
    color: #666;
    font-family: FuturaStd-Book;
    font-size: 10px;
}

.home-item-footer .price {
    font-weight: 700!important;
    text-align: center;
}

.home-categories {
    background: #fff;
    border-top: 0.4px solid rgba(0,0,0,.2);
    padding: 21px;
}

.home-categories-item {
    margin-bottom: 0;
    text-align: center;
    padding: 0;
}

.home-categories-link {
    color: #000;
    font-family: FuturaStd-Bold;
    font-size: .875rem;
    text-transform: uppercase;
    text-decoration: none;
}

.home-categories-link:hover {
    color: $primary-color;
    text-decoration:none; 
    cursor:pointer;  
}

.popup-content {
    height: fit-content;
    max-height: calc(100vh - 52px);
    overflow-y: auto;
    -ms-overflow-style: none; // IE and edge compatibility
    &::-webkit-scrollbar { // hide scrollbar but keep functionality
        display: none;
      }
    width: unset!important;
    @media screen and (max-width: "576px") {
        max-width: 90%;
    }
}

.popup {
    font-size: 12px;
    width: 500px;
    @media screen and (max-width: "576px") {
        width: unset;
    }
}

.popup-header {
    width: 100%;
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
    padding: 5px;
    font-family: FuturaStd-Bold;
    text-transform: uppercase;
}
.popup .content {
    font-family: FuturaStd-Book;
    font-size: 16px;
    width: 100%;
    padding: 10px 5px;
}
.popup .actions {
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 20px;
    text-align: center;
    @media screen and (max-width: "576px") {
        display: flex;
        justify-content: space-between;
    }
}

.popup .button {
    background: $primary-color;
    border: 0;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-family: FuturaStd-Bold;
    text-transform: uppercase;
    font-size: 12px;
    height: 50px;
    line-height: 50px;
    margin-right: 10px;
    width: 45%;
    @media screen and (max-width: "576px") {
        width: 48%!important;
        margin-right: unset;
    }
}

.popup .abort {
    background: #c1c1c1;
}

.popup .close {
    width: 16px;
    height: 16px;
    right: 0px;
    top: 0px;
    position: absolute;
    padding-right: 20px;
    cursor: pointer;
    color: #212528;
    opacity: unset;
    // cursor: pointer;
    // position: absolute;
    // display: block;
    // padding: 2px 5px;
    // line-height: 20px;
    // right: -10px;
    // top: -10px;
    // font-size: 24px;
    // background: #ffffff;
    // border-radius: 18px;
    // border: 1px solid #cfcece;
}

.store-button {
    border: 0;
    background: white;
    cursor: pointer;
    font-family: FuturaStd-Bold;
    text-transform: uppercase;
    font-size: 12px;
    height: 40px;
    line-height: 40px;
    width: 30%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    &-active {
        background: $primary-color;
        color: #fff;
    }
}

.toast-close {
    color: #fff;
}

.toast-close :hover,
.toast-close :active,
.toast-close :focus {
    color: #fff;
}

.toast-standard {
    background-color: #333;
    color: #fff;
	border: 1px solid hsla(0,0%,100%,.1);
    justify-content: space-between;
    text-transform: uppercase;
    margin: 80px 20px 0;
	font-size: 1rem;
	z-index: 99999;
    margin-left: 0;
    padding: 23px 30px;
    width: 100%;
}

.category-wrapper {
    padding-left: 25px;
    @media screen and (max-width: "576px") {
        padding-top: 10px;
        padding-left: unset;
    }
    font-family: FuturaStd-Book;
    color: #333;
    margin-top: 20px;
}

.category-header {
    padding-left: 60px;
    padding-top: 10px;
    @media screen and (max-width: "1200px") {
        padding-left: 20px;
    }
    @media screen and (min-width: "577px") {
        padding-bottom: 10px;
    }
    @media screen and (max-width: "576px") {
        padding-left: unset;
    }
    .category-name {
        font-size: 22px;        
        font-weight: bold;
    }
    .category-all {
        font-size: 13px;
        color: $primary-color;
        text-decoration: underline;
        cursor: pointer;
        padding-bottom: 10px;
    }
    .category-align {
        @media screen and (max-width: 767px) {
            text-align: right;
        }
    }
}

.category-container {
    // padding-top: 20px;
    @media screen and (max-width: "576px") {
        padding-top: 10px;
    }
}

.category-filter-item {
    margin: 8px 10px 8px 0;
    float: left;
}

// .category-subcategory {
//     padding-left: 60px;
//     @media screen and (max-width: "1200px") {
//         padding-left: 20px;
//     }
//     @media screen and (max-width: "576px") {
//         padding-left: unset;
//     }
//     .accordion-categories {
//         cursor: pointer;
//         &:hover {
//             color: $primary-color;
//         }
//         .category-title {    
//             font-family: FuturaStd-Bold;
//             font-size: 14px;
//             text-transform: uppercase;
//             display: inline-block;
//             margin-bottom: 33px;
//             @media screen and (max-width: "576px") {
//                 margin-bottom: 5px;
//             }
//         }
//     } 
// }

.category-sidebar {
    font-family: FuturaStd-Book;
    font-size: 16px;
    color: #333;
    padding: 0;
    // padding-bottom: 15px;
    @media screen and (max-width: "576px") {
        padding-bottom: unset;
    }
    list-style-type: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.category-sidebar :hover {
    @media screen and (min-width: 992px) {
        color: #333;
        cursor: pointer;
        font-family: FuturaStd-Heavy;
    }
}

.category-sidebar-option {
    color: #333;
    display: inline-block;
    padding: 5px 0;
    @media screen and (max-width: "576px") {
        padding: 5px 0;
    }
    cursor: pointer;
}

.category-sidebar-option-selected {
    color: #333;
    cursor: pointer;
    font-family: FuturaStd-Heavy;
}

.category-sidebar-sub {
    list-style-type: none;
    padding-bottom: 12px;
    padding-left: 20px;
    color: #333;
}

.category-sidebar-sub :hover {
    color: $primary-color;
    font-family: FuturaStd-Book;
}

.category-sidebar-sub-option {
    display: block;
    font-size: 14px;
    padding: 4px 0;
    font-family: FuturaStd-Book;
    color: #333;
    cursor: pointer;
}

.category-sidebar-sub-option-selected {
    color: $primary-color;
}

.category-banner {
    @media screen and (min-width: 992px) {
        height: 188px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
        height: 155px;
    }
    height: 110px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.cart-create-title {
    font-family: FuturaStd-Book;
    margin-bottom: 10px;
    color: $primary-color;
    font-size: 30px;
    padding-top: 40px;
    @media screen and (max-width: "576px") {
        font-size: 20px;
        padding-top: 10px;
    }

    font-weight: 400;
    margin: 0;
    line-height: 1.35;
}

.cart-create-select {
    margin-top: 30px;
    @media screen and (max-width: "576px") {
        margin-top: 15px;
    }
    height: 280px;
}

.dropdown-button {
    font-family: FuturaStd-Book;
    // background-image: url(../img/arrow_down.e06c6c31.png);
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center right 30px;
    border: 1px solid #D2D2D2;
    width: 100%;
    text-align: left;
    font-size: 18px;
    color: #333;
    padding: 15px 27px;
    border-radius: 25px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
    position: relative;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    background-color: #fff;

    &-link {
        color: $primary-color;
        &:hover {
            text-decoration: underline;
        }
    }
}

.dropdown-toggle::after {
    margin-top: 11px;
    float: right;
    margin-left: unset;
}

.dropdown--isOpen {
    background: #fff;
    overflow: auto;
    max-height: 210px;
    height: auto;
}

.dropdown-items {
    position: absolute;
    // padding-top: 50px;
    // top: 0;
    left: 0;
    right: 0;
    border-radius: 25px;
    //max-height: 0;
    // overflow: hidden;
    -webkit-transition: all .3s linear,max-height .6s ease-out;
    transition: all .3s linear,max-height .6s ease-out;
    background-color: #fff;
    overflow-x: hidden;
}

.dropdown-item {
    // margin-top: 10px;
    display: block;
    font-size: 18px;
    font-family: FuturaStd-Book;
    padding: 7px 26px;
    cursor: pointer;
}

.dropdown-items span:hover {
    background-color: #f7f7f7;
    color: $primary-color;
}

.dropdown-items span:active {
    background-color: #f7f7f7;
    color: $primary-color;
}

.bg {
    background-image: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(20).jpg");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.favorite-container {
    @media screen and (min-width: 992px) {
        padding-left: 20%;
    }
}

.payments-container {
    @media screen and (min-width: 992px) {
        padding-left: 20%;
        padding-right: 20%;
    }
}

.favorite-products {
    position: relative;
    padding : 0px 0px 0px 0px;
    font-size: 14px;
    font-family: FuturaStd-Book;
}

.favorite-products img {
    max-height: 70px;
    max-width: 70px;
}

.favorite-products-text {
    top: 20px;
}

.list-title-actions {
    display: flex;
    justify-content: space-between;
}

.max-product-row {
    max-width: 1800px;
}

.slist-modal{
    padding: 32px 24px;
    color: #1F2122;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .modal-header{
        .title{
            font-family: FuturaStd-Heavy;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: -1px;
        }
    }
    .modal-body{
        .subtitle{
            font-family: FuturaStd-Book;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
        .slists{
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            div{
                cursor: pointer;
                text-align: center;
                width: 200px;
                padding: 10px 32px;
                font-family: FuturaStd-Book;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                border-radius: 8px;
                background-color: white;
                border: 1px solid #1D2124;
                color: #1F2122;
            }
            div:hover{
                border: 1px solid #E2001A;
                color: #E2001A;
            }
        }
    }
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 8px;
    height: 8px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #E5E9EC;
    background-clip: padding-box;
    border: 1px solid #C9D0D6;
    opacity: 1;
    transition: opacity 0.6s ease;
    border-radius: 50%;
}
.carousel-indicators .active {
    background-color: #E2001A;
    border: 1px solid #E2001A;
}

.backgroundIndicator{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 40px !important;
    width: 40px;
    background-color: #E2001A;
}
// .sr-only{
//     position: unset !important;
// }

.products-grid {
    display: flex;
    column-gap: 16px;

    @media screen and (max-width: "576px") {
        column-gap: 10px;
    }

    row-gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.smartlist-header-button {
    height: 35px;
    margin-right: 20px;
    border: none;
    background-color: $primary-color;
    color: white;
    font-family: FuturaStd-Bold;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 8px;
    &:hover {
        transition: ease-in-out;
        transition-duration: 0.2s;
        border: 2px solid $primary-color!important;    
        background-color: transparent;
        color: $primary-color;
    }
    @media screen and (max-width: 576px) {
        width: 100%;
    }
    
    span {
        padding: 0 15px;
        letter-spacing: 0.6px;
    }

    &--secondary {
        border: 2px solid $primary-color!important;    
        background-color: transparent;
        color: $primary-color;
        .text {
            display: flex;
            justify-content: space-between;
        }
        &:hover {
            transition: ease-in-out;
            transition-duration: 0.2s;
            border: none;
            background-color: $primary-color;
            color: white;
        }
    }
}

.mobile {
    display: none !important;

    @media screen and (max-width: "991px") {
        display: unset !important;
    }
}

.desktop {
    display: none !important;

    @media screen and (min-width: "991px") {
        display: unset !important;
    }
}

.payment-modal{
    display: flex !important;
    justify-content: center;
    align-items: center;
    .modal-content{
        border: 0;
        background-color: transparent;
        .modal-iframe{
            border-radius: 25px;
            height: 325px;
            width: 450px;
            @media screen and (max-width: 499px) {
                height: 275px;
                width: 300px;
            }
        }
    }
}