.scrollDiv{
    position: sticky;
    bottom: 5px;
    justify-content: center;
}
.goUp{
    border-radius: 8px;
    padding: 7px 25px;
    background-color: white;
    color: #E2001A;
    font-family: FuturaStd-Heavy;
    font-size: 14px;
    border: 1px solid #E2001A;
    text-transform: uppercase;
    display: flex;
    align-items: center;


    &:hover {
        scale: 1.01;
    }
}