.faq{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 32px;
    &-title{
        font-size: 24px;
        font-family: 'FuturaStd-Bold';
        margin-bottom: 10px;
    }
    &-subtitle{
        font-size: 20px;
        font-family: 'FuturaStd-Heavy';
        margin-bottom: 5px;
    }
    &-question{
        padding-left: 10px;
        font-size: 18px;
        font-family: 'FuturaStd-Heavy';
        margin-bottom: 2px;
    }
    &-answer{
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        label{
            font-size: 16px;
            font-family: 'FuturaStd-Book';
        }
    }
}