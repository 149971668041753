@import '../../../scss/_variables.scss';

.card-product {
    width: 100%;
    max-width: 274px;
    margin: 0 20px 50px;
    height: 430px;
    background-color: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
    border-radius: 24px;
    .frozen{
        position: relative;
        height: 0;
        width: 0;
        svg{
            position: absolute;
            top: 0;
            right: -248px;
            z-index: 1;
        }
    }
    &:hover {
        @media screen and (min-width: 992px) {
            box-shadow: 0 10px 21px 0 $card-hover;
        }
    }

    &-container {
        position: relative;
        width: 100%;
        height: 122px;
    }

    &-slist{
        display: flex;
        justify-content: flex-end;
        align-items: start;
        width: 100%;
        padding: 16px;
        button{
            height: 40px;
            width: 45px;
            background-color: #fff;
            border: 0;
        }
        button:hover{
            scale: 1.02;
        }
    }

    &-image {
        text-align: center;
        line-height: 120px;
        
        img {
            @media screen and (max-width: "576px") {
                max-height: 100px;
                max-width: 100px;
                padding-bottom: unset;
            }
            padding-bottom: 10px;
            margin-top: 16px;
            max-height: 122px;
            max-width: 122px;
            z-index: 1;
        }
    }

    &-promo {
        position: absolute;
        z-index: 2;
        height: 26px;
        top: 15px;
        box-shadow: 1px 1px 2px 0 rgba(210, 31, 31, 0.39);
        background-color: #ff0000;
        text-transform: uppercase;
        span {
            padding-left: 12px;
            padding-right: 8px;
            font-family: FuturaStd-Bold;
            font-size: 10px;
            line-height: 2.2;
            letter-spacing: 0.4px;
            color: #ffffff;
        }
    }
    &-details {
        margin-top: 19px;
        padding-right: 10px;
        padding-left: 10px;
        height: 66px;
        span {
            font-family: FuturaStd-Heavy;
            font-size: 16px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.6px;
            color: #212529;
            display: block;
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            word-wrap: break-word;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
        .brand{
            font-size: 14px;
            font-family: FuturaStd-Book;
            line-height: 20px;
            padding: 16px 0;
        }
    }
    &-um {
        margin-top: 14px;
        padding-left: 10px;
        padding-right: 19px;
        @media screen and (max-width: 576px) {
            padding-right: 10px;
        }
        padding-bottom: 13px;
        font-size: 10px;
        height: 30px;
        line-height: 30px;
        letter-spacing: 0.87px;
        // color: $secondary-color;
        color: #666666;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icons {
            float: right;
            font-family: FuturaStd-Bold;
        }
        svg {
            width: 30px;
            @media screen and (max-width: 576px) {
                width: 25px;
            }
        }
    }
    &-promo-puglia {
        padding-left: 10px;
        .promo-container{
            display: flex;
            align-items: center;
            gap: 10px;
            .promo-title {
                font-family: FuturaStd-Bold;
                font-size: 12px;
                letter-spacing: 0.48px;
                color: white;
                text-transform: uppercase;
                background-color: #E2001A;
                border-radius: 1rem;
                padding: 4px 8px;
            }
            .promo-valid {
                font-family: FuturaStd-Book;
                font-size: 12px;
                letter-spacing: 0.48px;
                color: #E2001A;
            }
        }
    }
    &-prices {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        gap: 7px;
        .strike {
            font-size: 12px;
            letter-spacing: 1.05px;
            color: #7a7a7a;
        }
        .netTotal {
            font-size: 16px;
            @media screen and (max-width: "576px") {
                font-size: 12px;
            }
            font-family: FuturaStd-Bold;
            text-align: right;
        }
        .pricePerUM{
            font-size: 12px;
            color: #7D848C;
            line-height: 16px;
        }
    }
    &-buttons {
        margin-top: 16px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;

        // Coop-Puglia
        button {
            border-radius: 8px;
            background-color: white;
            border: 1px solid #E2001A;
            width: 40px;
            height: 40px;

            span {
                color: #ffffff;
            }
            img {
                width: 18px;
                height: auto;
            }
            svg {
                path {
                    fill: #E2001A;
                }
            }
            &:hover {
                scale: 1.02;
            }
        }
        .cart {
            margin-left: 15px;
            width: 40px;
            background-color: #E2001A;
            height: 40px;
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
     
    &-unavailable {
        margin-top: 16px;
        padding-left: 6px;
        padding-right: 6px;
        .unavailable-content {
            border-radius: 8px;
            background-color: #f2f2f2;
            text-align: center;
            padding: 5px;
            line-height: 1.2;
            span {
                font-size: 12px;
                @media screen and (max-width: "576px") {
                    font-size: 10px;
                }
                color: #d81818;
                text-transform: uppercase;
                font-family: FuturaStd-Book;
                letter-spacing: 1.5px;
            }
        }
    }
    &-icons {
        padding-left: 13px;
        width: 30px;
        height: 30px;
    }
    .Modal{
        width: unset !important;
        transform: translateX(50%) !important;
    }
}