.variants {
  display: flex !important;
  width: 110px !important;
  height: 110px !important;
  min-width: 80px;
  min-height: 80px;
  padding: 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #f5f5f5;
  background: #fff;
  margin-left: 10px;
  cursor: pointer;
  &-image {
    width: 44px;
    height: 44px;
    img {
      width: 100%;
    }
  }
  &-text {
    color: #333;
    text-align: center;
    font-size: 13px;
    letter-spacing: 0.1px;
    line-height: 20px;
    font-family: FuturaStd-Heavy;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    text-align: center !important;
    word-break: break-word;
  }
}
.selected {
  border: 1px solid #fda59a;
}
.variants-slider {
  padding: 16px;
  width: 95%;
//   @media screen and (max-width: "980px") {
//     width: 70vw;
//   }
//   @media screen and (max-width: "500px") {
//     width: calc(100vw - 100px);
//   }
//   @media screen and (max-width: "420px") {
//     width: calc(100vw - 50px);
//     padding-left: 32px;
//   }
  .slick-next,
  .slick-prev {
    background-color: #e2001a !important;
    border-radius: 50% !important;
    width: 30px;
    height: 30px;
    z-index: 1;
    display: flex;
    justify-content: center;
    padding: 5px;
  }
  //   .slick-slide .slick-active .slick-current{
  //     div{

  //     }
  //   }
}
