.slist-container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  .popup .actions {
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slist-title {
    display: flex;
    justify-content: space-between;
    label {
      font-family: FuturaStd-Bold;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -1px;
    }
    button {
      background-color: #e2001a;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-family: FuturaStd-Book;
      padding: 16px 24px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.32px;
      text-transform: capitalize;
      border: 0;
      height: 40px;
    }
    .abort {
      background: #c1c1c1;
    }
  }
  .list-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .list {
      height: fit-content;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 0;
      &-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: #1d2124;
        background-color: #f5f5f5;
        font-size: 16px;
        font-family: FuturaStd-Heavy;
        padding: 32px 16px;
        cursor: pointer;
        border-radius: 8px;
        div {
          background-color: transparent;
          font-size: 12px;
          font-family: FuturaStd-Book;
          display: flex;
          justify-content: center;
          min-width: 200px;
          gap: 5px;
          align-items: center;
          svg,
          path {
            width: 16px;
            height: 16px;
          }
        }
      }
      &-items {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        width: 100%;
        &-header {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 32px;
          button {
            border: 0;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            font-size: 16px;
            font-family: FuturaStd-Heavy;
            color: #1d2124;
          }
        }
        &-container {
          display: flex;
          flex-direction: column;
          padding: 5px;
          &-item {
            padding: 10px;
            display: flex;
            width: 100%;
            justify-content: space-evenly;
            align-items: center;
            border-bottom: 1px solid #eef1f3;
            gap: 70px;
            img {
              border-radius: 10px;
              width: 78px;
              height: 78px;
            }
            label {
              font-size: 14px;
              line-height: 20px;
              font-family: FuturaStd-Heavy;
              width: 30%;
            }
            button {
              border-radius: 8px;
              background-color: white;
              border: 1px solid #e2001a;
              width: 40px;
              height: 40px;
              color: #e2001a;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .cart {
              width: 40px;
              background-color: #e2001a;
              height: 40px;
              color: white;
            }
          }
          &-item:last-child {
            border-bottom: 0;
          }
        }
        &-footer {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 32px;
          .cart {
            width: 40px;
            background-color: #e2001a;
            height: 40px;
            color: white;
          }
          button {
            border-radius: 8px;
            background-color: white;
            border: 1px solid #e2001a;
            color: #e2001a;
            padding: 16px 24px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
          }
          .addToCart {
            display: inline-flex;
            height: 40px;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            border: 0;
            background: #e2001a;
            color: white;
          }
        }
      }
    }
  }
}

.slist-modal {
  .addToCart {
    display: inline-flex;
    height: 40px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 0;
    background: #e2001a;
    color: white;
  }
}
