.breadcrumb-container {
    display: flex;
    align-items: center;
    // padding-top: 20px;
    // padding-left: 40px;
    margin-bottom: 10px;
  
    @media screen and (max-width: "1279px") {
      padding-left: unset;
      //margin-top: 120px;
    }
  
    @media screen and (max-width: "1023px") {
      padding-left: unset;
    }
  
    .go-back-button {
      padding: unset;
      margin-right: 10px;
    }
  
    .breadcrumb {
      margin-bottom: unset !important;
    }
  }

  .product-container{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 100px;
    @media screen and (max-width: "980px") {
        flex-wrap: wrap;
    }
    .image-container{
        max-width: 50%;
        @media screen and (max-width: "980px") {
            max-width: unset;
        }
    }
    .view-container{
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 40%;
        @media screen and (max-width: "980px") {
            max-width: 100%;
        }
        div{
            text-align: left;
        }
    }
    .promo-container{
        display: flex;
        align-items: center;
        gap: 10px;
        .promo-title {
            font-family: FuturaStd-Bold;
            font-size: 12px;
            letter-spacing: 0.48px;
            color: white;
            text-transform: uppercase;
            background-color: #E2001A;
            border-radius: 1rem;
            padding: 4px 8px;
        }
        .promo-valid {
            font-family: FuturaStd-Book;
            font-size: 12px;
            letter-spacing: 0.48px;
            color: #E2001A;
        }
    }
  }

.counter-container{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    gap: 35px;
    @media screen and (max-width: "400px") {
        flex-wrap: wrap;
    }
    .product-detail-counter{
        margin: 0 !important;
    }
    button {
        border-radius: 8px;
        background-color: white;
        border: 1px solid #E2001A;
        width: 40px;
        height: 40px;

        span {
            color: #ffffff;
        }
        img {
            width: 18px;
            height: auto;
        }
        svg {
            path {
                fill: #E2001A;
            }
        }
        &:hover {
            scale: 1.02;
        }
    }
    .cart {
        margin-left: 15px;
        width: fit-content;
        background-color: #E2001A;
        height: 40px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 24px;
        gap: 16px;
        font-family: FuturaStd-Medium;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.32px;
        text-transform: capitalize;
        svg {
            path {
                fill: #fff;
            }
        }
    }
}

.offer{
    font-size: 12px;
    text-transform: uppercase;
    color: #DD0013;
    font-family: FuturaStd-Heavy;
    margin-left: -15px;
}