@import "../../../../scss/variables";

.mobile-header {
    // height: 133px;
    height: 110px;
    &-wrapper {
        flex-direction: column;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 102;
    }
    a:hover{
        text-decoration: none !important;
    }

    &-top {
        padding: 0 16px;
        height: fit-content;
        background-color: #FFFCFC;
        color: #1D2124;
        display: flex;
        align-items: center;
        height: fit-content;
        padding: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        .icon-box{
            height: 32px;
            width: 32px;
            background-color: #E2001A;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            svg,path{
                fill: white;
            }
            @media screen and (max-width: 800px) {
                display: none;
            }
        }
        .description-container{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        .description{
            font-family: FuturaStd-Book;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; 
        }
        nobr{
            margin-bottom: 3.5px;
        }

        span {
            // margin-top: 7px;
            // margin-bottom: 7px;
            font-family: FuturaStd-Bold;
            font-size: 10px;
            @media screen and (max-width: "576px") {
                font-size: 8px;
            }
            letter-spacing: 0.39px;
            text-transform: uppercase;
        }
        .pin {
            width: 11px;
            height: 14px;
            object-fit: contain;
            margin-right: 10px;
            margin-top: 8px;
            margin-bottom: 8px;
            &-link {
                color: unset;
                text-decoration: none;
            }
        }
        .shop {
            width: 11px;
            height: 11px;
            object-fit: contain;
            margin-right: 10px;
            margin-top: 10px;
            margin-bottom: 9px;
            &-link {
                color: unset;
                text-decoration: none;
            }
        }
        .shop-link {
            span {
                letter-spacing: 0.87px;
            }
        }
    }

    &-bottom {
        padding-right: 16px;
        height: 55px;
        background-color: #ffffff;
        border-bottom: 1px solid #edeaea;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo-container{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
        .burger-menu{
            margin-top: -18px;
            .bm-menu-wrap {
                top: 0;
            }
            .bm-burger-button {
                button{
                    position: fixed !important;
                    width: 50px !important;
                    height: 55px !important;
                }
            }
            // .burger-menu-content{
            //     padding-bottom: 0.5px;
            // }
        }
        .logo {
            // height: 29px;
            // width: 139px;
            // Coop-Puglia
            width: 70px;
            margin-top: 6px;
            margin-bottom: 10px;
            img {
                max-width: 100%;
            }
        }
    }

    &-search {
        padding-left: 16px;
        padding-right: 16px;
        height: 48px;
        background-color: #ffffff;
        display: flex;
        padding-top: 9px;
        padding-bottom: 9px;
        border-bottom: 1px solid #edeaea;
        &-icon {
            width: 18px;
            height: 18px;
        }

        form {
            display: flex;
            width: 100%;
            margin-top: unset;
            position: relative;
        }

        input {
            border-radius: 0;
            -webkit-appearance: none;
            height: 30px;
            width: 100%;
            border: 1px solid $top-header-search;
            padding-left: 8px;
            padding-right: 5px;
            border-radius: 2rem;
            &::placeholder {
                letter-spacing: 0.87px;
                font-size: 10px;
                color: $top-header-search;
            }
        }
        button {
            width: 70px;
            height: 30px;
            background-color: transparent;              
            border: none;
            position: absolute;
            right: 10px;
            position: absolute;
            right: 10px;
            svg, path{
                fill: #878F96;
            }
        }
    }
}