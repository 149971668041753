$sub-color: grey;
$main-color: black;

@mixin shrinkLabel {
  top: -14px;
  font-size: 12px;
}

.group {

  .form-input {
    background: none;
    background-color: white;
    font-size: 14px;
    padding: .375rem .75rem;
    padding-left: 0;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    line-height: 1.5;
    border-bottom: 1px solid #ced4da;
    font-family: FuturaStd-Bold;
    
    &:focus {
      outline: none;
    }

    &:focus ~ .form-input-label {
      @include shrinkLabel();
    }
  }

  .input-required {
    border-bottom: 1px solid #dd0202!important;
  }

  input[type='password'] {
    letter-spacing: 0.3em;
  }

  .form-input-label {
    color: rgba(51,51,51,.5);
    font-family: FuturaStd-Book;
    font-size: 14.5px;
    line-height: 1.5;
    position: absolute;
    pointer-events: none;
    left: 100;
    top: .375rem;
    transition: 300ms ease all;

    &.shrink {
      @include shrinkLabel();
    }
  }
}
