@import "../../../scss/variables";

.footer {
    position: relative;
    bottom: 0;
    width: 100%;
    &-content {
        min-height: 250px;
        background-color: $primary-color;
        color: #ffffff;   
        @media screen and (max-width: 991px) {
            padding-top: 24px;
            padding-bottom: 20px;
        }
        @media screen and (max-width: 767px) {
            padding-top: 40px;
            padding-bottom: 20px;
        }
        padding-top: 65px;
        padding-bottom: 55px;
        .row {
            padding-left: 45px;
            padding-right: 25px;
            @media screen and (max-width: 991px) {
                padding-left: 39px;
                padding-right: 12px;
            }
        }
        .mobile-col {
            @media screen and (max-width: 576px) {
                margin-bottom: 20px;
            }
        }
        // img {
        //     width: 100%;
        //     height: 76px;
        //     object-fit: contain;
        // }
    }

    &-bottom {
        height: 50px;
        @media screen and (max-width: 567px) {
            height: 99px;
        }
        background-color: #900214;
        padding-left: 45px;
        padding-right: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            font-family: FuturaStd-Medium;
            font-size: 14px;
            color: #ffffff;
            .bold {
                font-family: FuturaStd-Bold;
            }
        }
    }

    &-link {
        font-family: FuturaStd-Bold;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.82px;
        color: #ffffff;
        padding-bottom: 20px;
        cursor: pointer;
    }

    &-text {
        font-family: FuturaStd-Book;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.52px;
        color: #ffffff;
        padding-bottom: 11px;
        .bold {
            font-family: FuturaStd-Bold;
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: 0.52px;
            color: #ffffff;
        }
    }

    &-apps {
        padding-right: 10px;
        padding-bottom: 12px;
        @media screen and (max-width: 767px) {
            display: flex;
            justify-content: center;
        }
        @media screen and (max-width: 576px) {
            display: unset;
            justify-content: unset;
        }
        button {
            border-radius: 8px;
            background-color: #fca311;
            border: solid 1px #fca311;
            margin-bottom: 20px;
            text-transform: uppercase;
            width: 204px;
            @media screen and (max-width: 567px) {
                width: 100%;
            }
            @media screen and (max-width: 767px) {
                margin-right: 10px;
            }
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
                color: #ffffff;
                padding-left: 10px;
            }
            img {
                width: 15px;
                height: auto;
            }
            svg {
                path {
                    fill: #ffffff;
                }
            }
            &:hover {
                box-shadow: 0 3px 0 0 #c97d00;  
            }
        }
        a {
            text-decoration: none;
        }
    }

    &-social {
        padding-top: 15px;
        align-items: center;
        @media screen and (max-width: 767px) {
            justify-content: center;
        }
        img {
            width: 27px;
            height: unset;
            max-width: 100%;
        }
        .icon {
            margin-left: 20px;
            margin-right: 15px;
        }
        &-icon {
            margin-left: 20px;
        }
    }
}

