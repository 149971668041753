@import "../../../scss/variables";

.breadcrumb {
    background-color: unset!important;
    // font-size: 10px;
    font-size: 14px;
    font-family: FuturaStd-Medium;
    text-transform: uppercase;
    color: #333333!important;
    margin: unset!important;
    //margin-bottom: 11px !important;
   // padding: unset!important;
    letter-spacing: 0.09px;
    padding-left: unset !important;
    @media screen and (max-width: "991px") {
        display: none!important;
    }
    a {
        color: unset;
    }
}

.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 5px!important;
    color: #333333!important;
    content: ">"!important; 
}

.breadcrumb-item.active {
    color: #333333!important;
    font-family: FuturaStd-Bold;
}