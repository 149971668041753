.terms{
    display: flex;
    flex-direction: column;
    padding: 32px;
    &-title{
        font-size: 24px;
        font-family: 'FuturaStd-Bold';
        margin-bottom: 10px;
    }
    &-subtitle{
        font-size: 20px;
        font-family: 'FuturaStd-Heavy';
        margin-bottom: 5px;
    }
    &-description{
        padding: 10px 20px;
        font-size: 16px;
        font-family: 'FuturaStd-Book';
    }
}