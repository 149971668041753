.profile-container {
    .profile-breadcrumb {
        padding-top: 25px;
        padding-left: 25px;
        display: flex;

        @media screen and (max-width: "991px") {
            padding-top:10px;
            margin-top: 50px;
            padding-left: 15px;
        }
    }
    .grid-left {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 212px;
    }
    .profile-content {
        @media screen and (min-width: "991px") {
            padding-right: 40px;
        }
    }
    .logout-button{
        border-radius: 8px;
        border: 1px solid #E2001A;
        background: #FFF;
        display: flex;
        height: 48px;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        font-size: 16px;
        text-transform: uppercase;
        font-family: FuturaStd-Heavy;
        color: #E2001A;
        width: 100%;
    }
}